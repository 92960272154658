.landingPage {
  background: linear-gradient(to bottom, #1d1c25, #431e3f);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

.spoilerPage {
  color: white;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.searchInput {
  font-size: 24px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: all 0.3s;
  text-align: center;
}

.searchInput:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.7);
  background-color: rgba(0, 0, 0, 0.1);
}

.searchInputSpoiler {
  font-size: 24px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  width: 38%;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: all 0.3s;
  text-align: center;
}

.searchInputSpoiler:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.7);
  background-color: rgba(0, 0, 0, 0.1);
}

.searchButton {
  background: #413543;
  padding: 10px 20px;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.searchButton:hover {
  background-color: #421d3f;
}

.logo {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.subheader {
  font-size: 1.2rem;
  font-weight: lighter;
  margin-bottom: 2rem;
}

.cardResults {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
}

.card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  color: #ffffff;
  margin: 0.5rem;
  padding: 1rem;
  text-align: center;
  width: 300px;
}

.card a {
  color: #ffffff;
  text-decoration: none;
}

.card a:hover {
  text-decoration: underline;
}

.cardResultsContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

.setsHeading {
  border-bottom: 1px solid white;
}

.setNames {
  padding: 10px;
  cursor: pointer;
}

.cardSetNames {
  padding: 1px;
  cursor: pointer;
  font-size: 16px;
}

.setNames:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.05);
}

.cardSetNames:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.05);
}

.cardNames:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.05);
}

.unoCard {
  border-radius: 23px;
  object-fit: contain;
  width: 70%;
}

.cardNames {
  margin: 5px;
  padding: 5px;
  cursor: pointer;
}

.toggleContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.checkboxInput {
  margin-right: 0.5rem;
}

/* Add styling for filter section */
.filterSection {
  margin-top: 1rem;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 4px;
  width: fit-content;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

/* LandingPage.module.css */

.filterRow {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Add this line to align filter headings */
}

.filterGroup {
  flex: 1;
  margin-right: 1rem;
  align-items: flex-start; /* Change this line to align checkboxes */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Change this line to align checkboxes */
}

button {
  margin: 5px 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:focus {
    outline: none;
  }
}

button:focus {
  outline: none;
}

.activeToggle {
  background: linear-gradient(to right, #F7971E, #FFD200);
  color: #fff;
  border: 1px solid #F7971E;
}

.inactiveToggle {
  background: #fff;
  color: #000;
  border: 1px solid #fff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Add these styles at the bottom of your LandingPage.module.css file */

.loaderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.pingLoader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #431e3f;
  animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
}

/* Filter */



@keyframes ping {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.noResults {
  color: #ffffff;
  font-size: 1.5rem;
  margin-top: 2rem;
  text-align: center;
}

@media (max-width: 850px) {
  .filterContainer {
    width: auto !important;
  }
}

@media (max-width: 768px) {
  .unoCard {
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
  }
}

.filterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px; /* Adjust the desired width value */
  margin: 0 auto; /* Center the container horizontally */
}

.colorFilterRow,
.rarityFilterOptions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.colorFilterItem,
.checkboxLabel {
  margin-right: 10px;
}

.hiddenCheckbox {
  display: none;
}

/* Add these CSS styles to your existing CSS file  */

.checked {
  /* border: 2px solid #92338a; */
  border-radius: 50%;
  /* width: 40px; 
  height: 40px;  */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 4px;  */
  color: #92338a;
  box-sizing: content-box;
  padding: 5px; 
  background: linear-gradient(to right, #92338a, #92338a);
}

/* Align the filter options in a horizontal row */
.colorFilterRow {
  display: flex;
  align-items: center;
}

/* Add margin between the filter options */
.colorFilterItem:not(:last-child) {
  margin-right: 10px;
}

/* Style the multicolor label */
.multicolorLabel {
  margin-top: 10px;
}

/* Add these styles to your CSS file */
.checked-rarity {
  font-weight: bold;
  color: #92338a;
}

.colorlessChecked {
  border: 2px solid #000; /* Add any desired border styles */
  border-radius: 50%; /* Create a circular border */
}

@media (max-width: 850px) {
  .filterContainer {
    width: auto !important;
  }
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.cardCountWithPadding {
  padding-top: 10px; /* Adjust the value as needed */
}

.logoImg {
  width: 400px;
  height: auto;
  margin-bottom: 20px;
  background-image: url("./assets/mtgreprints.png");
  background-size: cover;
}

/*Test commit*/

