.landingPage {
    background: linear-gradient(to bottom, #1d1c25, #431e3f);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
  }
  
  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 500px;
    width: 100%;
  }
  
  .logo {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .subheader {
    font-size: 1.2rem;
    font-weight: lighter;
    margin-bottom: 2rem;
  }
  
  .googleSignInButton {
    width: 100%;
    font-size: 18px;
    padding: 10px;
    margin-top: 20px;
    color: white;
    background-color: #413543;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .googleSignInButton:hover {
    background-color: #421d3f;
  }
  
  .googleSignInButton svg {
    margin-right: 8px;
  }
  
  