.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10vh;
    background-color: #282c34;
    backdrop-filter: blur(10px); /* for transparency */
    position: sticky;
    top: 0;
    transition: background-color 0.3s ease;
    z-index: 1000;
    color: white;
    padding: 0 2em;
  }
  
  .navbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1400px;
    width: 100%;
  }
  
  .navbar.scrolled {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  
  .greeting {
    font-size: calc(10px + 2vmin);
    text-align: center;
    flex-grow: 1; /* helps center the greeting */
  }
  
  .links {
    display: flex;
    gap: 1em;
    font-size: calc(10px + 1vmin);
  }
  
  .link {
    text-decoration: none;
    color: #F9B722;
    transition: color 0.3s ease;
    position: relative;
  }
  
  .link:hover {
    color: #F1A700;
  }
  
  .link:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #F9B722;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }
  
  .link:hover:before {
    visibility: visible;
    transform: scaleX(1);
  }

  @media screen and (max-width: 950px) {
    .greeting {
        margin-right: 15px;
    }
}
.logoContainer {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .logo {
      display: none;
  }
}

.logo {
  width: 65px; /* Adjust the width as needed */
  height: 65px; /* Adjust the height as needed */
  /*margin-right: 10px; /* Adjust the spacing between the logo and text */
  background-image: url("../assets/mtgreprints.png");
}

.textLogo {
  width: 200px; /* Adjust the width as needed */
  height: auto; /* Adjust the height as needed */
  margin-right: 10px; /* Adjust the spacing between the logo and text */
  background-image: url("../assets/text.png");
}

.logoText {
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-decoration: none !important;
  background-image: url("../assets/mtgreprints.png");
}

.logoText:hover {
  text-decoration: none !important;
}

.logoLink {
  text-decoration: none;
  color: inherit;
}

.logoLink:hover {
  text-decoration: none;
}
