.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(to bottom, #1d1c25, #431e3f);
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-content {
  flex: 1;
  font-size: calc(10px + 1vmin);
  color: #282c34;
}

.App-footer {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 1vmin);
  color: white;
}

.Corners {
  border-radius: 14px;
}
