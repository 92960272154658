.modal {
    background-color: white;
    width: 300px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
}

form label {
    display: block;
    margin-bottom: 10px;
}

form input, form select, form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
}

form button {
    width: 100%;
    padding: 10px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

form button:hover {
    background-color: darkblue;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    position: relative; /* For positioning the close button */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
}

.gradient-button {
    background: linear-gradient(to right, #F7971E, #ffd200); /* Gradient background from darker to #f1a700 */
    color: white; /* White text color */
    border: none; /* No border */
    padding: 10px 20px; /* Padding around the text */
    font-size: 16px; /* Font size */
    font-weight: bold; /* Font weight */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background 0.3s; /* Smooth transition for hover effect */
}

.gradient-button:hover {
    background: linear-gradient(to right, #f1a700, #c07800); /* Inverted gradient on hover */
}



